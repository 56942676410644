import React, { useEffect, useState } from "react"
import { Spinner, Card, Table, Row, Button, Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { usePartner } from "@src/partner"
import {
  deletePartnerUuidRoles,
  getPartnerUuidRoles,
  getPartnerUuidUsers,
  PARTNER_USERS,
  postPartnerUuidRoles,
  getPartnerUuidInvitations,
  INVITES,
  ROLES_TITLE,
  postPartnerUuidInvitations,
  USER_ROLE,
} from "@src/api"
import { colors } from "@src/constants"
import { InputField } from "@src/components"
import { PencilFill, PersonPlusFill, Trash3Fill } from "react-bootstrap-icons"
import { ModalView } from "@src/components/Modal"
import { useAuthentication } from "@src/auth"

const TABLE_COLUMNS = ["users", "email", "roles", "", ""]
const ROLES = [
  { key: ROLES_TITLE.role_admin, value: "Admin" },
  { key: ROLES_TITLE.role_manager, value: "Manager" },
]

export const PartnerAccessScreen: React.FC = () => {
  const { t } = useTranslation()
  const { brand, partner, updateUserRoles } = usePartner()
  const [deleteLoader, setDeleteLoader] = useState<number>(0)
  const [showEdit, setShowEdit] = useState<boolean>(false)
  const [showDelete, setShowDelete] = useState<boolean>(false)
  const [showUser, setShowUser] = useState<boolean>(false)
  const [editUser, setEditUser] = useState<USER_ROLE>()
  const [deleteUser, setDeleteUser] = useState<USER_ROLE>()
  const [invitations, setInvitations] = useState<INVITES[]>([])
  const [preExistingRole, setPreExistingRole] = useState<ROLES_TITLE>()
  const [selectRole, setSelectRole] = useState<ROLES_TITLE>(ROLES_TITLE.role_admin)
  const [inviteUserEmail, setInviteUserEmail] = useState<string>("")
  const [resendInvite, setResendInvite] = useState<string>("")
  const [userRoles, setuserRoles] = useState<PARTNER_USERS[]>([])
  const { userId, userEmail } = useAuthentication()
  const validEmail = new RegExp("^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,5}$")
  const [isIoTUser, setIsIoTUser] = useState<boolean>(false)

  useEffect(() => {
    if (partner) {
      getPartnerInvitations()
      updateUserRoles(partner.uuid)
      getPartnerUsersRoles()
    }
  }, [partner, isIoTUser])

  useEffect(() => {
    if (userEmail && userEmail.includes("iot-venture.com")) {
      setIsIoTUser(true)
    } else {
      setIsIoTUser(false)
    }
  }, [userEmail])

  const getPartnerUsersRoles = () => {
    getPartnerUuidRoles(partner.uuid).then(({ response, data }) => {
      if (response.ok) {
        const userRolesData = data
        // filter out the user email that does not include iot-venture
        const filteredData = userRolesData.map((item) => ({
          ...item,
          users: item.users.filter((user) => !user.email.includes("iot-venture.com")),
        }))

        if (isIoTUser) {
          setuserRoles(data)
        } else {
          setuserRoles(filteredData)
        }
      }
    })
  }

  const deleteUserRole = (user: USER_ROLE, isEdit: boolean) => {
    setDeleteLoader(user.id)
    deletePartnerUuidRoles(partner.uuid, {
      user_ids: [user.id],
      role: user.role,
    }).then(({ response, data }) => {
      setShowDelete(false)
      if (response.ok) {
        getPartnerUsersRoles()
        setDeleteLoader(0)
        isEdit ? editUserRole() : partner && updateUserRoles(partner.uuid)
      }
    })
  }

  const editUserRole = () => {
    postPartnerUuidRoles(partner.uuid, {
      user_ids: [editUser.id],
      role: editUser.role,
    }).then(({ response, data }) => {
      setShowEdit(false)
      setShowUser(false)
      getPartnerUsersRoles()
      if (response.ok && partner) {
        updateUserRoles(partner.uuid)
      }
    })
  }

  const getPartnerInvitations = () => {
    getPartnerUuidInvitations(partner.uuid).then(({ response, data }) => {
      if (response.ok) setInvitations(data)
    })
  }

  const sendInvite = () => {}

  const renderSpinner = () => {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" />
      </div>
    )
  }

  const renderSendInviteModal = () => {
    return (
      <ModalView
        title={t("settings.partner.invite_users")}
        buttonTitle={t("settings.partner.send_invite")}
        disabled={!validEmail.test(inviteUserEmail)}
        show={showUser}
        buttonLoader={false}
        onHideModal={() => setShowUser(false)}
        onButtonClick={() => {
          setShowUser(false)
          console.log("selectRole", selectRole)
          partner &&
            postPartnerUuidInvitations(partner.uuid, { email: inviteUserEmail, role: selectRole }).then(
              ({ response, data }) => {
                setSelectRole(ROLES_TITLE.role_admin)
                getPartnerInvitations()
              },
            )
        }}>
        <div className="d-flex flex-column mb-3">
          <InputField
            label={t("settings.fleets.email")}
            value={""}
            onChangeText={(text) => setInviteUserEmail(text)}
            onPersist={() => {}}
            validate={!validEmail.test(inviteUserEmail)}
            errorMsg={t("settings.fleets.validEmailError")}
            loading={false}
          />
          <b className="small mb-2">{t("settings.partner.roles")}:</b>
          <Form.Select
            name="roles-invite"
            id="role-invite"
            onChange={(event) => {
              const selectedValue = event.target.value
              setSelectRole(ROLES.filter((val) => val.value == selectedValue)[0].key)
            }}
            defaultValue={ROLES[0].value}>
            {ROLES.map((item, i) => (
              <option key={i} value={item.value}>
                {item.value}
              </option>
            ))}
          </Form.Select>
        </div>
      </ModalView>
    )
  }

  const renderEdit = () => {
    const handleClose = () => setShowEdit(false)
    return (
      <ModalView
        title={t("settings.partner.editRoles")}
        buttonTitle={t("settings.partner.save")}
        show={showEdit}
        buttonLoader={false}
        onHideModal={handleClose}
        onButtonClick={() => {
          deleteUserRole({ ...editUser, role: preExistingRole }, true)
        }}>
        <div className="d-flex flex-column">
          <b className="small mt-3">{t("settings.partner.email")} : </b>
          <label>{editUser && editUser.email}</label>
        </div>
        <div className="d-flex flex-column">
          <b className="small mt-3">{t("settings.partner.roles")} : </b>
          <div className="mt-2">
            <Form.Select
              name="roles"
              id="role-select"
              onChange={(event) => {
                setEditUser({ ...editUser, role: event.target.value as ROLES_TITLE })
              }}
              defaultValue={editUser ? editUser.role : ROLES_TITLE.role_admin}>
              {ROLES.map((item, i) => (
                <option key={i} value={item.key}>
                  {item.value}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>
      </ModalView>
    )
  }

  const renderCard = (text: string) => {
    return (
      <Card
        className="d-flex align-items-center justify-content-center"
        style={{
          height: 25,
          backgroundColor: colors.middleGray,
          borderWidth: 1,
          borderColor: text == t("settings.partner.pending_invite") ? brand.color : colors.gentleGray,
        }}>
        <b
          className="small ms-1 me-1"
          style={{
            textAlign: "center",
          }}>
          {text}
        </b>
      </Card>
    )
  }

  const renderTableLayout = () => {
    return (
      <Table responsive>
        <thead>
          <tr style={{ whiteSpace: "nowrap" }}>
            {TABLE_COLUMNS.map((item, index) => (
              <th key={index}>
                <div className="d-flex flex-row align-items-center">
                  {item != "" ? t(`settings.partner.${item}`) : ""}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {invitations.map((item: INVITES, i) => (
            <tr style={{ whiteSpace: "nowrap" }} key={i}>
              <td>
                <label>{renderCard(t("settings.partner.pending_invite"))}</label>
              </td>
              <td>
                <label>{item.email ?? "-"}</label>
              </td>
              <td>{renderCard(ROLES.filter((val) => val.key == item.role)[0].value)}</td>
              <td>
                <Card
                  className="d-flex align-items-center justify-content-center pointer"
                  style={{
                    borderWidth: 1,
                    borderColor: brand.color,
                    borderRadius: ".25rem",
                    height: 25,
                  }}
                  onClick={() => {
                    setResendInvite(item.email)
                    partner &&
                      postPartnerUuidInvitations(partner.uuid, { email: item.email, role: item.role }).then(
                        ({ response, data }) => {
                          setResendInvite("")
                        },
                      )
                  }}>
                  {resendInvite == item.email ? (
                    <Spinner animation="border" className="m-1" size="sm" />
                  ) : (
                    <b className="small">{t("settings.partner.resend_invite")}</b>
                  )}
                </Card>
              </td>
              <td></td>
            </tr>
          ))}
          {userRoles &&
            userRoles?.map((user: PARTNER_USERS, i) =>
              user.users?.map((item: USER_ROLE, i) => (
                <tr key={i} style={{ whiteSpace: "nowrap" }}>
                  <td>
                    <label>
                      {item.firstname} {item.lastname}
                    </label>
                  </td>
                  <td>
                    <label>{item.email}</label>
                  </td>
                  <td>{renderCard(ROLES.filter((val) => val.key == user.name)[0].value)}</td>
                  <td>
                    {userId !== item.id && (
                      <PencilFill
                        className="pointer ms-4"
                        color={brand.color}
                        onClick={() => {
                          setEditUser({ ...item, role: user.name })
                          setPreExistingRole(user.name)
                          setShowEdit(true)
                        }}
                      />
                    )}
                  </td>
                  <td>
                    {userId !== item.id && (
                      <>
                        {deleteLoader == item.id ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          <Trash3Fill
                            className="pointer"
                            color={colors.red}
                            onClick={() => {
                              setDeleteUser({ ...item, role: user.name })
                              setShowDelete(true)
                            }}
                          />
                        )}
                      </>
                    )}
                  </td>
                </tr>
              )),
            )}
        </tbody>
        {renderEdit()}
        {renderSendInviteModal()}
        <ModalView
          title={t("settings.partner.remove_user")}
          buttonTitle={t("settings.partner.remove")}
          show={showDelete}
          buttonLoader={false}
          onHideModal={() => setShowDelete(false)}
          onButtonClick={() => {
            deleteUserRole(deleteUser, false)
          }}>
          {deleteUser && <label>{t("settings.partner.areYouSureRemove") + deleteUser.email + "?"}</label>}
        </ModalView>
      </Table>
    )
  }

  return (
    <div>
      {userRoles.length == 0 ? (
        renderSpinner()
      ) : (
        <>
          {renderTableLayout()}
          <Row>
            <div className="d-flex flex-row-reverse">
              <Button
                variant="outline-dark"
                onClick={() => {
                  setInviteUserEmail("")
                  setShowUser(true)
                }}>
                <div>
                  <PersonPlusFill size={18} />
                </div>
              </Button>
            </div>
          </Row>
        </>
      )}
    </div>
  )
}
