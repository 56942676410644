import React, { useEffect } from "react"
import { Tabs, Tab } from "react-bootstrap"
import { useSearchParams } from "react-router-dom"
import { PartnerAccessScreen } from "./PartnerAccessScreen"
import { ManageTrackers } from "./ManageTrackers"
import { AddTrackers } from "./AddTrackers"
import { BookTrackerService } from "./BookTrackerService"
import { PartnerDetail } from "./PartnerDetail"
import { useTranslation } from "react-i18next"

const TABS = ["access_rights", "brand", "manage", "add", "book_tracker", "invitations"]
const imgBorderRadius = { borderRadius: 10 }

export const TABLE_COLUMNS = ["users", "email", "roles"]

export const PartnerTabs: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = searchParams.get("tab")
  const { t } = useTranslation()

  useEffect(() => {
    if (!TABS.includes(tab)) setSearchParams({ tab: "access_rights" }, { replace: true })
  }, [setSearchParams, tab])

  return (
    <Tabs activeKey={tab} onSelect={(key) => setSearchParams({ tab: key })} className="mb-3">
      <Tab eventKey={TABS[0]} title={t("settings.partner.tabs.access_rights")}>
        <PartnerAccessScreen />
      </Tab>
      <Tab eventKey={TABS[1]} title={t("settings.partner.tabs.brand")}>
        <PartnerDetail />
      </Tab>
      {/* the below code is added for a temporary flow until the backend is ready  */}
      <Tab eventKey={TABS[2]} title={t("settings.partner.alert")}>
        <ManageTrackers />
      </Tab>
      <Tab eventKey={TABS[3]} title={t("settings.partner.add_tracker")}>
        <AddTrackers />
      </Tab>
      <Tab eventKey={TABS[4]} title={t("settings.partner.book_service.service_extension")}>
        <BookTrackerService />
      </Tab>
    </Tabs>
  )
}
