import React, { useEffect } from "react"
import { ListGroup, Form, Row, Col, Button } from "react-bootstrap"
import { Page } from "@src/components"
import { usePartner } from "./usePartner"
import { useTranslation } from "react-i18next"
import { useFleets } from "@src/settings"
import { InvitationsList } from "@src/invite"
import { CreatePartner } from "./CreatePartner"

const COLUMN = { marginTop: "10rem", borderRadius: 10 }

export const SelectPartnerScreen: React.FC = () => {
  const { partners, changePartner, clearPartner, updatePartners } = usePartner()
  const { updateFleetUuid } = useFleets()
  const { t } = useTranslation()
  const [createPartner, setCreatePartner] = React.useState(false)

  // Auto redirect if only one partner is active
  useEffect(() => {
    clearPartner()
    // if (partners?.length === 1) changePartner(partners[0].uuid)
    updateFleetUuid("")
  }, [partners])

  return (
    <Page fullscreen>
      <Row className="justify-content-md-center">
        <Col md="8" lg="6" className="shadow-lg bg-body" style={COLUMN}>
          {createPartner ? (
            <CreatePartner
              onCancel={() => setCreatePartner(false)}
              onPartnerCreated={() => {
                setCreatePartner(false)
                updatePartners()
              }}
            />
          ) : (
            <Form className="mt-4 mb-4 ms-3 me-3 loginform_2">
              <div className="d-flex justify-content-between mb-2">
                <Form.Label className="fw-bold align-self-center mt-2">
                  {t("select_partner_screen.partner_selection").toUpperCase()}
                </Form.Label>
              </div>
              <ListGroup defaultActiveKey="#link1">
                <ListGroup.Item
                  key={0}
                  action
                  onClick={() => {
                    setCreatePartner(true)
                  }}
                  style={{ fontWeight: "bold" }}>
                  {t("select_partner_screen.create_partner")}
                </ListGroup.Item>
                {partners?.map((partner, i) => (
                  <ListGroup.Item
                    key={i + 1}
                    action
                    onClick={() => {
                      changePartner(partner.uuid)
                    }}>
                    {partner.name}
                  </ListGroup.Item>
                ))}
                {partners?.length === 0 && (
                  <ListGroup.Item>{t("select_partner_screen.no_partner_available")}</ListGroup.Item>
                )}
              </ListGroup>
            </Form>
          )}
          <InvitationsList />
        </Col>
      </Row>
    </Page>
  )
}
