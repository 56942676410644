import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Card, ListGroup, Form, Row, Col, Modal, Spinner } from "react-bootstrap"
import { sendEmailViaMailgun } from "@src/api" // Adjust the import based on your API location
import { useAuthentication } from "@src/auth" // Adjust the import based on your auth hook location
import { InputField, ModalView } from "@src/components"
import { usePartner } from "@src/partner"
import { Trash3Fill } from "react-bootstrap-icons"
import { colors } from "@src/constants"
import { alignCenterBetween } from "@src/dashboard/components"
import { useUser } from "@src/user"

const styles = {
  listGroup: {
    overflow: "scroll",
    flex: 1,
    display: "flex",
  },
  divBox: {
    display: "flex",
    flexDirection: "column" as const,
    height: "350px",
    marginBottom: "20px",
  },
  warningCard: { backgroundColor: "#f8f9fa" },
  toggleButton: { marginTop: "10px" },
}

export const AddTrackers: React.FC = () => {
  const { t } = useTranslation()
  const { userEmail } = useAuthentication()
  const [serialNumbers, setSerialNumbers] = useState<string[]>([])
  const [inputValue, setInputValue] = useState<string>("")
  const [showModal, setShowModal] = useState<boolean>(false)
  const [apiLoader, setApiLoader] = useState<boolean>(false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [fleetName, setFleetName] = useState<string>("")
  const { partner } = usePartner()
  const { user } = useUser()

  const validateSerialNumber = (serial: string): boolean => {
    return /^\d{9,10}$/.test(serial)
  }

  const handleAddSerialNumber = () => {
    if (validateSerialNumber(inputValue)) {
      if (serialNumbers.includes(inputValue)) {
        setErrorMessage(t("settings.partner.serial_number_exists"))
        return
      }
      setSerialNumbers([...serialNumbers, inputValue])
      setInputValue("")
      setErrorMessage("")
    } else {
      setErrorMessage(t("settings.partner.invalid_serial_number"))
    }
  }

  const handleRemoveSerialNumber = (serial: string) => {
    setSerialNumbers(serialNumbers.filter((sn) => sn !== serial))
  }

  const handleRequestAddTrackers = async () => {
    setShowModal(false)
    setApiLoader(true)

    const mailContent = `This is a generated message from the fleet portal: http://dashboard.iot-venture.com\n\n“${
      user.firstname + " " + user.lastname
    }” (${userEmail}) has requested to add the following trackers to "${partner.name}" (${
      partner.uuid
    }):\n${serialNumbers.join(
      ", ",
    )}.\n\nPlease follow the link, create a group and copy the serial numbers into the group:\nhttps://itsmybike.cloud/groups/new`

    const response = await sendEmailViaMailgun({
      from: "fleetportal@itsmybike.com",
      subject: "Request to Add Trackers",
      text: mailContent,
    })
    if (response.message) {
      setSerialNumbers([])
      setApiLoader(false)
      setShowSuccess(true)
      setFleetName("")
    }
  }

  return (
    <div>
      {showSuccess && (
        <Card style={styles.warningCard} className="mt-3 mb-4 p-2">
          {t("settings.partner.mail_toast")}
        </Card>
      )}
      <Form.Group className="mb-1">
        <div className="mb-2">
          <b className="small ">{t("settings.partner.enter_serial_no")}</b>
        </div>

        <div className="d-flex flex-row justify-content-center">
          <Form.Control
            type="text"
            placeholder={t("settings.partner.input_placeholder")}
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value)
              setErrorMessage("")
            }}
          />
          <Button className=" ms-2" onClick={handleAddSerialNumber}>
            {t("settings.partner.add")}
          </Button>
        </div>

        {errorMessage && <div className="text-danger mt-2">{errorMessage}</div>}
      </Form.Group>
      <ListGroup className="mb-3 mt-2">
        {serialNumbers.map((serial) => (
          <ListGroup.Item key={serial}>
            <div className={alignCenterBetween}>
              <div>{serial}</div>
              <Trash3Fill className="pointer" color={colors.red} onClick={() => handleRemoveSerialNumber(serial)} />
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>

      <Button
        className="btn btn-primary-outline mt-1"
        onClick={() => setShowModal(true)}
        disabled={serialNumbers.length === 0}>
        {t("settings.partner.request_add_trackers")}
      </Button>

      <ModalView
        title={t("settings.partner.request_add_trackers")}
        buttonLoader={false}
        buttonTitle={t("settings.partner.request")}
        show={showModal}
        onHideModal={() => setShowModal(false)}
        onButtonClick={handleRequestAddTrackers}>
        <label>{t("settings.partner.request_add_trackers_alert")}</label>
      </ModalView>
    </div>
  )
}
