import React, { CSSProperties, useEffect, useState } from "react"
import { Table, Spinner, Alert, Form } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Page, LockUnlockIcon, ModalView, PermissionCard, PaginationItem } from "@src/components"
import { getDevice } from "@src/api"
import { SLIM_DEVICE_TYPE } from "@src/devices/types"
import { useLocalization } from "@src/localization"
import * as Icon from "react-bootstrap-icons"
import { usePartner } from "@src/partner"
import { colors } from "@src/constants"
import { SortArrowIcon } from "./devicedetail/tabs/components"
import { useFleets } from "@src/settings"
import { DEVICE_LOADING_STATE, useDevice } from "./useDevice"
import { StyleSheet } from "@src/types"

export const TABLE_COLUMNS = [
  { key: "locked", value: "" },
  { key: "serialnumber", value: "serialnumber" },
  { key: "name", value: "device_name" },
  { key: "battery", value: "batterie_status" },
  { key: "servicebooking", value: "active_service" },
  { key: "last_position_timestamp", value: "last_position" },
  { key: "last_seen_timestamp", value: "last_seen" },
]

const NOWRAP: CSSProperties = { whiteSpace: "nowrap" }
const DEVICES_LENGTH = [20, 50, 100]

export const colorTagStyle = (colors: string) => ({
  color: colors,
})

export const DevicesScreen: React.FC = () => {
  const navigate = useNavigate()
  const { formatDateTime } = useLocalization()
  const { t } = useTranslation()
  const { partner, devicesLength, updateDeviceLength, permissions } = usePartner()
  const [devices, setDevices] = useState<SLIM_DEVICE_TYPE[]>([])
  const [activePage, setActivePage] = useState<number>(0)
  const [pagesCount, setPagesCount] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(true)
  const [sorting, setSorting] = useState<boolean>(true)
  const [sortHeader, setSortedHeader] = useState<string>("last_seen_timestamp")
  const [headerLoad, setHeaderLoad] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const { fleet } = useFleets()
  const { loadDevice, clearDevice, clearLastTrips } = useDevice()

  useEffect(() => {
    partner && changePage(0)
  }, [partner?.uuid, permissions])

  useEffect(() => {
    setLoading(true)
    listDevices()
    clearDevice()
    clearLastTrips()
  }, [sorting, sortHeader, activePage, fleet, devicesLength])

  const handleOnClick = (item: SLIM_DEVICE_TYPE, event: MouseEvent) => {
    loadDevice(item.serialnumber, DEVICE_LOADING_STATE.load)
    if (event.metaKey || event.ctrlKey) {
      const win = window.open(`/${partner?.uuid}/device/${item.serialnumber}`, "_blank")
      win?.focus()
    } else {
      navigate(`/${partner?.uuid}/device/${item.serialnumber}`)
    }
  }

  const listDevices = () => {
    getDevice(partner?.uuid, fleet && fleet.uuid, {
      page: activePage,
      per_page: devicesLength,
      sort_desc: sorting,
      sort_by: sortHeader,
    }).then(({ response, data }) => {
      if (response.ok) {
        setDevices(data.devices)
        setPagesCount(Math.ceil(data.meta.total / data.meta.per_page))
      }
      setLoading(false)
      setHeaderLoad(false)
    })
  }

  const changePage = (page: number) => {
    if ((page < 0 || pagesCount < page) && !!partner) return
    setLoading(activePage !== page)
    setActivePage(page)
  }

  const onClickTableHeader = (selectedHeader: string) => {
    selectedHeader !== sortHeader ? setSorting(true) : setSorting(!sorting)
    if (selectedHeader !== "servicebooking") {
      setSortedHeader(selectedHeader)
      setHeaderLoad(true)
    }
  }
  // if (!permissions?.can_see_device_list) return <PermissionCard />

  return (
    <Page
      showFleet
      showHeader
      headerValue={t("sidebar.devices")}
      onSelectFleet={() => {
        setLoading(true)
        setDevices([])
      }}>
      <div className="d-flex align-items-start flex-row">
        <PaginationItem activePage={activePage} pagesCount={pagesCount} onChangePage={(page) => changePage(page)} />
        <div className="ms-2">
          <Form.Select
            className="d-flex"
            name="fleets"
            id="fleets"
            onChange={(event) => {
              const eventTargetValue = parseInt(event.target.value)
              setActivePage(0)
              eventTargetValue > 50 ? setShowModal(true) : updateDeviceLength(eventTargetValue)
            }}
            defaultValue={devicesLength}>
            {DEVICES_LENGTH.map((item) => (
              <option key={item} value={item} selected={item == devicesLength}>
                {item}
              </option>
            ))}
          </Form.Select>
        </div>
      </div>
      {loading && (
        <div className="d-flex justify-content-center mt-4 mb-4">
          <Spinner animation="border" />
        </div>
      )}
      {devices.length == 0 && !loading && <Alert variant={"secondary"}>{t("devices_screen.no_device")}</Alert>}
      {devices.length > 0 && !loading && (
        <Table hover responsive>
          <thead>
            <tr style={NOWRAP}>
              {TABLE_COLUMNS.map(({ key, value }) => (
                <th
                  key={key}
                  className={key !== "servicebooking" ? "pointer" : ""}
                  onClick={() => onClickTableHeader(key)}>
                  {value && (
                    <div className="d-flex flex-row align-items-center">
                      {t(`devices_screen.${value}`)}
                      {sortHeader == key && (
                        <SortArrowIcon
                          loading={headerLoad}
                          arrowUp={
                            ["last_seen_timestamp"].includes(key) || ["last_position_timestamp"].includes(key)
                              ? !sorting
                              : sorting
                          }
                        />
                      )}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {devices &&
              devices.map((device, i) => (
                <tr key={i} onClick={(event) => handleOnClick(device, event)} style={NOWRAP} className="pointer">
                  <td>
                    {!device.reported_theft ? (
                      <LockUnlockIcon locked={device.locked} />
                    ) : (
                      <Icon.Flag style={colorTagStyle(colors.red)} />
                    )}
                  </td>
                  <td>
                    <div className="d-flex flex-row justify-content-between">
                      <div>{device.serialnumber}</div>
                      <div>
                        {device.powersaving_ends_at && <Icon.LightningChargeFill color={colors.mustardYellow} />}
                      </div>
                    </div>
                  </td>
                  <td>{device.name}</td>
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      <label>{device.battery} %</label>
                      {device.charging && <Icon.BatteryCharging className="ms-1" color={colors.green} size={20} />}
                    </div>
                  </td>
                  <td>
                    {(device?.servicebooking && formatDateTime(new Date(device.servicebooking.service_end_date))) ||
                      "-"}
                  </td>
                  <td>
                    {(device?.servicebooking &&
                      device?.last_position_timestamp &&
                      formatDateTime(new Date(device.last_position_timestamp))) ||
                      "-"}
                  </td>
                  <td>
                    {(device?.servicebooking &&
                      device?.last_seen_timestamp &&
                      formatDateTime(new Date(device.last_seen_timestamp))) ||
                      "-"}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
      <ModalView
        title={t("settings.fleets.alert")}
        buttonTitle={t("device_screen.ok")}
        show={showModal}
        buttonLoader={false}
        onHideModal={() => setShowModal(false)}
        onButtonClick={() => {
          setShowModal(false)
          updateDeviceLength(100)
        }}>
        <label>{t("device_screen.pagination_msg")}</label>
      </ModalView>
    </Page>
  )
}
