import React from "react"
import { SLIM_DEVICE_TYPE } from "@src/devices/types"
import { Card, Col, ListGroup, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { usePartner } from "@src/partner"
import { sortArrowStyles } from "@src/device/devicedetail/tabs/components"
import { StyleSheet } from "@src/types"
import { InfoCircle } from "react-bootstrap-icons"
import RadialChart from "./RadialChart"

type DevicesCardProps = {
  title: string
  devices: SLIM_DEVICE_TYPE[]
  loading: boolean
  showDetail?: boolean
  isRadial?: boolean
  totalRadialValue?: string
  tooltip?: string
  showTotal?: boolean
  totalValue?: number
  onDetailClick?: (devices: SLIM_DEVICE_TYPE[]) => void
  onDeviceClick: (device: SLIM_DEVICE_TYPE) => void
  formatDeviceTitle: (device: SLIM_DEVICE_TYPE) => string
}

const pageClassName = "shadow-2 bg-white rounded mt-2 mb-2"

export const DevicesCard: React.FC<DevicesCardProps> = ({
  title,
  devices,
  loading,
  showDetail,
  isRadial,
  totalRadialValue,
  tooltip,
  showTotal,
  totalValue,
  onDetailClick,
  onDeviceClick,
  formatDeviceTitle,
}) => {
  const { t } = useTranslation()
  const { brand } = usePartner()
  const styles: StyleSheet = {
    cardBody: {
      display: "flex",
      flexDirection: "column",
      height: "305px",
    },
    listGroup: {
      overflow: "scroll",
      flex: 1,
      display: "flex",
    },
    options: {
      flex: 1,
      borderWidth: 1,
      borderColor: "#dee2e6",
      borderRadius: "30px",
      maxWidth: "458px",
    },
    cardTitle: {
      color: "#636E72",
      fontSize: "18px",
    },
    cardValue: {
      color: brand?.color,
    },
  }

  const Link = ({ id, children }) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{tooltip}</Tooltip>}>
      <a href="#">{children}</a>
    </OverlayTrigger>
  )

  return (
    <Col>
      <Card style={styles.options} className={pageClassName}>
        <Card.Body style={styles.cardBody}>
          <div className="d-flex flex-row align-items-center justify-content-between mb-2">
            <Card.Title
              className={`d-flex flex-row me-1 ${onDetailClick ? "pointer" : ""}`}
              style={styles.cardTitle}
              onClick={onDetailClick}>
              {title}
              <div className="ps-2 me-1" style={{ width: "35px" }}>
                <Link id="info">
                  <InfoCircle color="#636E72" size={15} />
                </Link>
              </div>
            </Card.Title>

            {showTotal && !loading && (
              <div>
                <b className="fs-2" style={styles.cardValue}>
                  {totalValue}
                </b>
              </div>
            )}
          </div>
          <div
            className="d-flex flex-row"
            style={{
              overflowY: "auto", // Enables vertical scrolling
              overflowX: "hidden", // Prevents horizontal scrolling
            }}>
            <ListGroup className="d-flex p-1" style={{ flex: 1 }}>
              {devices.length === 0 && !loading ? (
                <ListGroup.Item className="d-flex">{t("dashboard_screen.no_devices")}</ListGroup.Item>
              ) : (
                <>
                  {devices.map((device) => (
                    <ListGroup.Item
                      // className="d-flex"
                      key={device.serialnumber}
                      className="list-group-item list-group-item-action pointer mb-1"
                      style={{ backgroundColor: "#F8F9FA" }}
                      as="a"
                      onClick={() => onDeviceClick(device)}>
                      {formatDeviceTitle(device)}
                    </ListGroup.Item>
                  ))}
                </>
              )}
              {loading && devices.length == 0 && <Spinner style={sortArrowStyles.loader} animation="border" />}
            </ListGroup>
            {isRadial && totalRadialValue && (
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  maxWidth: "150px",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                  marginTop: "20px",
                }}>
                <RadialChart totalValue={1060} value={1040} size={100} strokeWidth={10} />
              </div>
            )}
          </div>

          {/* <div className="d-flex flex-row-reverse mt-2">
            {showDetail && (
              <label className="pointer" onClick={() => onDetailClick(devices)}>
                {t("dashboard_screen.detail")}
              </label>
            )}
          </div> */}
        </Card.Body>
      </Card>
    </Col>
  )
}
