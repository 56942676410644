import React, { useEffect, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import * as Icon from "react-bootstrap-icons"
import { useLocation, Link, useNavigate } from "react-router-dom"
import { DeviceSearch } from "@src/components"
import { DEFAULT_PERMISSIONS, PERMISSIONS, usePartner } from "@src/partner"
import { colors } from "@src/constants"
import { colorTagStyle } from "@src/device"
import { useAuthentication } from "@src/auth"

const NAVBAR_ITEMS = [
  {
    i18n: "sidebar.home_screen",
    route: "",
    icon: Icon.House,
  },
  {
    i18n: "sidebar.devices",
    route: "/devices",
    icon: Icon.Bicycle,
  },
  {
    i18n: "sidebar.maps",
    route: "/map",
    icon: Icon.Map,
  },
  {
    i18n: "sidebar.theft_report",
    route: "/theft",
    icon: Icon.Bell,
  },
  {
    i18n: "sidebar.energy_saving",
    route: "/energysaving",
    icon: Icon.Plug,
  },
]

const NAVBAR_ITEMS_SETTINGS = [
  {
    i18n: "settings.sidebar.user",
    route: "/settings/user",
    icon: Icon.Person,
  },
  {
    i18n: "settings.sidebar.partner",
    route: "/settings/partner",
    icon: Icon.People,
  },
  {
    i18n: "settings.sidebar.fleets",
    route: "/settings/fleets",
    icon: Icon.Collection,
  },
]
import { DEVICE_LOADING_STATE, useDevice } from "@src/device/useDevice"

type SideMenuProps = {
  isSettings?: boolean
}

export const SideMenu: React.FC<SideMenuProps> = ({ isSettings }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const location = useLocation()
  const { brand, partner, permissions } = usePartner()
  const [searchText, setSearchText] = useState<string>("")
  const [navItems, setNavItems] = useState<Array<any>>([])
  const { loadDevice } = useDevice()
  const [permissionsSettings, setPermissionsSettings] = useState<PERMISSIONS>()

  useEffect(() => {
    if (permissions) {
      setPermissionsSettings(permissions)
    }
  }, [permissions, isSettings])

  useEffect(() => {
    navSetup()
  }, [permissionsSettings])

  const navSetup = () => {
    if (isSettings) {
      let nav_items = [
        {
          i18n: "settings.sidebar.user",
          route: "/settings/user",
          icon: Icon.Person,
        },
        {
          i18n: "settings.sidebar.partner",
          route: "/settings/partner",
          icon: Icon.People,
        },
        {
          i18n: "settings.sidebar.fleets",
          route: "/settings/fleets",
          icon: Icon.Collection,
        },
        {
          i18n: "settings.sidebar.geofence",
          route: "/settings/geofences",
          icon: Icon.Geo,
        },
      ]
      !permissionsSettings?.manageUserRoles && nav_items.splice(1, 1)
      !permissionsSettings?.createFleets && nav_items.splice(1, 1)
      setNavItems(nav_items)
    } else {
      let nav_items = [
        {
          i18n: "sidebar.home_screen",
          route: "",
          icon: Icon.House,
        },
        {
          i18n: "sidebar.devices",
          route: "/devices",
          icon: Icon.Bicycle,
        },
        {
          i18n: "sidebar.maps",
          route: "/map",
          icon: Icon.Map,
        },
        {
          i18n: "sidebar.theft_report",
          route: "/theft",
          icon: Icon.Bell,
        },
        {
          i18n: "sidebar.energy_saving",
          route: "/energysaving",
          icon: Icon.Plug,
        },
      ]

      nav_items = handlepermissionsSettings(nav_items)
      setNavItems(nav_items)
    }
  }

  const handlepermissionsSettings = (NavItems: any[]) => {
    let nav_items = NavItems
    if (!permissionsSettings?.viewTheftContact) {
      const theftIndex = nav_items.findIndex((item) => item.i18n == "sidebar.theft_report")
      nav_items.splice(theftIndex, 1)
    }
    if (!permissionsSettings?.can_see_energy_saving) {
      const powerSaveIndex = nav_items.findIndex((item) => item.i18n == "sidebar.energy_saving")
      nav_items.splice(powerSaveIndex, 1)
    }

    if (!permissionsSettings?.can_see_device_list) {
      const deviceIndex = nav_items.findIndex((item) => item.i18n == "sidebar.devices")
      nav_items.splice(deviceIndex, 1)
    }

    if (!permissionsSettings?.can_see_map) {
      const mapIndex = nav_items.findIndex((item) => item.i18n == "sidebar.maps")
      nav_items.splice(mapIndex, 1)
    }
    return nav_items
  }

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value.toString())
  }
  const buildRoute = (route: string) => {
    return "/" + partner?.uuid + route
  }

  const Navigation = () => {
    return (
      <ul className="nav nav-pills flex-column mb-auto">
        {navItems.map((item, i) => (
          <li className="nav-item" key={i}>
            <Link
              to={buildRoute(item.route)}
              className={`nav-link link-dark ${location.pathname === buildRoute(item.route) && "brand-bg"}`}
              aria-current="page">
              <div className="d-flex justify-content-start align-items-center">
                <item.icon className="bi me-2" width="16" height="16" />
                <span>{t(item.i18n)}</span>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    )
  }

  return (
    <div className="d-flex flex-column flex-shrink-0  navbar-light pt-4">
      {isSettings ? (
        <Button className="btn btn-primary-outline" onClick={() => navigate(`/${partner.uuid}`)}>
          <div className="d-flex justify-content-start align-items-center">
            <Icon.ArrowLeft className="me-3" size={15} />
            {t("settings.sidebar.back")}
          </div>
        </Button>
      ) : (
        <Form.Control
          type="search"
          id="search"
          autoComplete="off"
          placeholder={t("sidebar.search_placeholder")}
          value={searchText}
          onChange={onSearchChange}
        />
      )}
      <hr />
      {searchText.length == 0 ? (
        <Navigation />
      ) : (
        <DeviceSearch
          searchText={searchText}
          onClick={(result) => {
            loadDevice(result.serialnumber, DEVICE_LOADING_STATE.load)
            navigate(`/${partner?.uuid}/device/${result.serialnumber}`)
            setSearchText("")
          }}
        />
      )}
      <hr />
    </div>
  )
}
