import brandingConfig from "@src/branding/config.json";

// Extract the partner domains from the config file
const productionDomains = [
  "dashboard.iot-venture.com",
  ...Object.values(brandingConfig.partners).map((partner) => partner.domain),
];

// check the production domains contains the whitelabel staging test domain
const includes_staging = productionDomains.includes("staging-iot-dashboard.herokuapp.com") 

// Determine the environment
export const env: "production" | "staging" = productionDomains.includes(window.location.hostname) || !includes_staging
  ? "production"
  : "staging"

// export const env: "production" | "staging" =
//   window.location.hostname == "dashboard.iot-venture.com" ? "production" : "staging"
