import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Card, CardTitle, Col, Row, Spinner } from "react-bootstrap"
import { Page } from "@src/components"
import { SLIM_DEVICE_TYPE } from "@src/devices/types"
import { useLocalization } from "@src/localization"
import { useTranslation } from "react-i18next"
import { usePartner } from "@src/partner"
import { useFleets } from "@src/settings"
import { colors, config } from "@src/constants"
import { StatisticCard, DevicesCard } from "./components"
import { centerAlign } from "@src/device/devicedetail/tabs/components"
import "./Dashboard.css"
import { StyleSheet } from "@src/types"
import { SYSTEM_OF_MEASUREMENT, useUser } from "@src/user"
import {
  getCo2,
  getDevicesCount,
  getDevicesWithCriticalBattery,
  getDevicesWithExpiringServicebooking,
  getDevicesWithMovementCount,
  getDevicesWithMovementLasthour,
  getDevicesWithNoServicebooking,
  getDevicesWithTripCount,
  getDevicesWithValidPositionCount,
  getDevicesWithValidPositionLastDay,
  getDistance,
  getExpiredServiceBooking,
  getLockedDevices,
  getLowBatteryDevices,
  getMissingServicebookingDevices,
  getSpeed,
  getTheftsReported,
  getTrips,
  getUnlockedDevices,
} from "@src/api/requests/statistics"
import { getDevice } from "@src/api"
import pLimit from "p-limit"
import { useDevice } from "@src/device/useDevice"

type STATISTICS_DATA = {
  devices_count: string
  trip_count: string
  movement_count: string
  movement_lasthour_count: string
  valid_position_count: string
  valid_position_lastday_count: string
  no_servicebooking_count: string
  expiring_servicebooking_count: string
  critical_battery_devices_count: string
  locked_devices_count: string
  unlocked_devices_count: string
  theft_reported_devices_count: string
  avg_distance: string
  total_distance: string
  avg_co2: string
  total_co2: string
  avg_trips: string
  total_trips: string
  avg_speed: string
  total_speed: string
}

const defaultStatisticsData: STATISTICS_DATA = {
  devices_count: "",
  trip_count: "",
  movement_count: "",
  movement_lasthour_count: "",
  valid_position_count: "",
  valid_position_lastday_count: "",
  no_servicebooking_count: "",
  expiring_servicebooking_count: "",
  critical_battery_devices_count: "",
  locked_devices_count: "",
  unlocked_devices_count: "",
  theft_reported_devices_count: "",
  avg_distance: "",
  total_distance: "",
  avg_co2: "",
  total_co2: "",
  avg_trips: "",
  total_trips: "",
  avg_speed: "",
  total_speed: "",
}

const styles: StyleSheet = {
  backgroundDrop: {
    backgroundColor: "rgba(0,0,0,0.3)",
    width: "100%",
    height: "100%",
    zIndex: 1,
    top: 0,
    left: 0,
    bottom: 0,
    borderRadius: ".25rem",
    position: "absolute",
  },
  loaderView: {
    paddingTop: 10,
    paddingLeft: 5,
    paddingRight: 5,
    display: "flex",
    backgroundColor: colors.smokeWhite,
    height: "65px",
    zIndex: 999,
    borderRadius: 8,
    flexDirection: "column",
  },
  borderStyles: {
    flex: 1,
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "14px",
    paddingBottom: "14px",
    borderWidth: 1,
    marginTop: "20px",
    borderColor: "#F0F0F0",
    borderRadius: "10px",
    height: "inherit",
  },
  cardTitle: {
    color: "#636E72",
    fontSize: "17px",
    marginTop: "10px",
    marginBottom: "10px",
    fontWeight: "bold",
  },
}

export const DashboardScreen: React.FC = () => {
  const navigate = useNavigate()
  const { formatDate } = useLocalization()
  const { t } = useTranslation()
  const { partner, permissions } = usePartner()
  const [loading, setLoading] = useState<boolean>(true)
  const [statisticsData, setStatisticsData] = useState<STATISTICS_DATA>(defaultStatisticsData)
  const [unit, setUnit] = useState<SYSTEM_OF_MEASUREMENT>()
  const { fleet } = useFleets()
  const { user, distanceConversion } = useUser()
  const [expiredDevices, setExpiredDevices] = useState<SLIM_DEVICE_TYPE[]>([])
  const [expiringDevices, setExpiringDevices] = useState<SLIM_DEVICE_TYPE[]>([])
  const [missingServiceBookingDevices, setMissingServiceBookingDevices] = useState<SLIM_DEVICE_TYPE[]>([])
  const [lowBatteryDevices, setLowBatteryDevices] = useState<SLIM_DEVICE_TYPE[]>([])
  const [devices, setDevices] = useState<SLIM_DEVICE_TYPE[]>([])
  const [deviceLoad, setDeviceLoad] = useState<boolean>(false)
  const { clearDevice } = useDevice()
  const DAY_IN_MILLISECONDS: number = 24 * 60 * 60 * 1000
  const limit = pLimit(8)

  useEffect(() => {
    if (partner) {
      setLowBatteryDevices([])
      setMissingServiceBookingDevices([])
      setExpiringDevices([])
      setLoading(true)
      setDeviceLoad(true)
      getStatistics()
      fetchDevices()
      // getDevicesListForStatistics()
    }
  }, [partner, fleet])

  useEffect(() => {
    if (user) setUnit(user.system_of_measurement)
  }, [user])

  const fetchDevices = async () => {
    setDeviceLoad(true)
    getDevice(partner?.uuid, fleet && fleet.uuid, {
      page: 0,
      per_page: config.itemsPerPage,
    }).then(({ response, data }) => {
      if (response.ok) {
        setDevices(data.devices)
        setLowBatteryDevices(data.devices.filter((device) => device.battery < 10))
        setMissingServiceBookingDevices(data.devices.filter((device) => device.servicebooking === null))
        setExpiringDevices(
          data.devices.filter(
            (device) =>
              device.servicebooking !== null &&
              new Date(device.servicebooking.service_end_date).getTime() - new Date().getTime() <=
                90 * DAY_IN_MILLISECONDS,
          ),
        )
        setDeviceLoad(false)
      }
    })
  }

  const getDevicesListForStatistics = async () => {
    const partnerUuid = partner?.uuid
    if (!partnerUuid) {
      return
    }

    try {
      // Use Promise.all to make the API calls concurrently
      const [expiredDevices, missingServiceBookingDevices, lowBatteryDevices] = await Promise.all([
        getExpiredServiceBooking(partnerUuid, fleet?.uuid, {
          partner_id: partner?.uuid,
          fleetUuid: fleet?.uuid,
          page: 0,
          per_page: 20,
          months: 2,
        }),
        getMissingServicebookingDevices(partnerUuid, fleet?.uuid, {
          partner_id: partner?.uuid,
          fleetUuid: fleet?.uuid,
          page: 0,
          per_page: 20,
        }),
        getLowBatteryDevices(partnerUuid, fleet?.uuid, {
          partner_id: partner?.uuid,
          fleetUuid: fleet?.uuid,
          page: 0,
          per_page: 20,
        }),
      ])

      // Process the API results and update state
      // setExpiredDevices(expiredDevices?.data?.devices || [])
      // setMissingServiceBookingDevices(missingServiceBookingDevices?.data?.devices || [])
      // setLowBatteryDevices(lowBatteryDevices?.data?.devices || [])
    } catch (error) {
      console.error("Error fetching devices for statistics:", error)
    } finally {
      // Set loading to false after all operations
      // setLoading(false)
    }
  }

  const retryRequest = async (fn: Function, retries: number = 3, delay: number = 1000) => {
    try {
      return await fn()
    } catch (error) {
      if (retries === 0 || error?.response?.status !== 429) {
        throw error
      }
      console.log(`Request failed, retrying... (Attempts remaining: ${retries})`)
      await new Promise((resolve) => setTimeout(resolve, delay))
      return retryRequest(fn, retries - 1, delay * 2) // Exponential backoff
    }
  }

  const getStatistics = async () => {
    setLoading(true)
    setStatisticsData(defaultStatisticsData)

    const partnerUuid = partner?.uuid

    // Functions to call, wrapped in limit
    const functions = [
      () => retryRequest(() => getDevicesCount(partnerUuid, fleet?.uuid)),
      () => retryRequest(() => getDevicesWithMovementCount(partnerUuid, fleet?.uuid)),
      () => retryRequest(() => getDevicesWithMovementLasthour(partnerUuid, fleet?.uuid)),
      () => retryRequest(() => getDevicesWithTripCount(partnerUuid, fleet?.uuid)),
      () => retryRequest(() => getDevicesWithValidPositionCount(partnerUuid, fleet?.uuid)),
      () => retryRequest(() => getDevicesWithValidPositionLastDay(partnerUuid, fleet?.uuid)),
      () => retryRequest(() => getDevicesWithNoServicebooking(partnerUuid, fleet?.uuid)),
      () => retryRequest(() => getDevicesWithExpiringServicebooking(partnerUuid, fleet?.uuid)),
      () => retryRequest(() => getDevicesWithCriticalBattery(partnerUuid, fleet?.uuid)),
      () => retryRequest(() => getLockedDevices(partnerUuid, fleet?.uuid)),
      () => retryRequest(() => getUnlockedDevices(partnerUuid, fleet?.uuid)),
      () => retryRequest(() => getTheftsReported(partnerUuid, fleet?.uuid)),
      () => retryRequest(() => getDistance(partnerUuid, fleet?.uuid)),
      () => retryRequest(() => getCo2(partnerUuid, fleet?.uuid)),
      () => retryRequest(() => getTrips(partnerUuid, fleet?.uuid)),
      () => retryRequest(() => getSpeed(partnerUuid, fleet?.uuid)),
    ]

    try {
      // Limit concurrency, process each function with throttling
      const results = await Promise.all(functions.map((fn) => limit(fn)))

      const [
        devicesCount,
        devicesWithMovementCount,
        devicesWithMovementLastHour,
        devicesWithTripCount,
        devicesWithValidPositionCount,
        devicesWithValidPositionLastDay,
        devicesWithNoServicebooking,
        devicesWithExpiringServicebooking,
        devicesWithCriticalBattery,
        devicesWithLocked,
        devicesWithUnlocked,
        devicesWithTheftReported,
        distance,
        Co2Data,
        tripDetail,
        speed,
      ] = results

      const analysisData: STATISTICS_DATA = {
        devices_count: `${devicesCount?.data?.count || 0}`,
        trip_count: `${devicesWithTripCount?.data?.count || 0}`,
        movement_count: `${devicesWithMovementCount?.data?.count || 0}`,
        movement_lasthour_count: `${devicesWithMovementLastHour?.data?.count || 0}`,
        valid_position_count: `${devicesWithValidPositionCount?.data?.count || 0}`,
        valid_position_lastday_count: `${devicesWithValidPositionLastDay?.data?.count || 0}`,
        no_servicebooking_count: `${devicesWithNoServicebooking?.data?.count || 0}`,
        expiring_servicebooking_count: `${devicesWithExpiringServicebooking?.data?.count || 0}`,
        critical_battery_devices_count: `${devicesWithCriticalBattery?.data?.count || 0}`,
        locked_devices_count: `${devicesWithLocked?.data?.count || 0}`,
        unlocked_devices_count: `${devicesWithUnlocked?.data?.count || 0}`,
        theft_reported_devices_count: `${devicesWithTheftReported?.data?.count || 0}`,
        avg_distance: `${distanceConversion(distance?.data?.avg_distance || 0)}`,
        total_distance: `${distanceConversion(distance?.data?.total_distance || 0)}`,
        avg_co2: `${Math.round(Co2Data?.data?.avg_co2 || 0)} ${Co2Data?.data?.avg_co2_unit || ""}`,
        total_co2: `${Math.round(Co2Data?.data?.total_co2 || 0)} ${Co2Data?.data?.total_co2_unit || ""}`,
        avg_trips: `${tripDetail?.data?.avg_trips || 0}`,
        total_trips: `${tripDetail?.data?.total_trips || 0}`,
        avg_speed: `${speed?.data?.avg_speed || 0} ${speed?.data?.avg_speed_unit || ""}`,
        total_speed: `${speed?.data?.total_speed || 0} ${speed?.data?.total_speed_unit || ""}`,
      }
      if (analysisData.devices_count && !fleet) {
        config.itemsPerPage = devicesCount?.data?.count
      }
      setStatisticsData(analysisData)
    } catch (error) {
      console.error("Error fetching statistics:", error)
    } finally {
      setLoading(false)
    }
  }

  const onDeviceClick = (device: SLIM_DEVICE_TYPE) => {
    clearDevice()
    navigate(`/${partner?.uuid}/device/${device.serialnumber}`)
  }

  const renderLoaderSpinner = () => {
    return (
      <>
        <div className={centerAlign} style={styles.backgroundDrop}>
          <div className={centerAlign} style={styles.loaderView}>
            <Spinner className="align-self-center" animation="border" size="sm" />
            <b className="m-2">{t("dashboard_screen.loader")}</b>
          </div>
        </div>
      </>
    )
  }

  const totalDevices = devices.filter((device) => device.servicebooking !== null).length
  const reportedThefts = devices.filter((device) => !!device.reported_theft).length
  const lockedDevices = devices.filter((device) => device.locked === "locked").length

  const renderTopRow = () => {
    return (
      <Row>
        <StatisticCard
          show={true}
          title={t("dashboard_screen.total_trackers")}
          totalCount={statisticsData.devices_count}
          loading={loading}
          isDeviceActive={true}
          tooltip={t("dashboard_screen.tooltip.total_trackers")}
          onButtonClick={() => navigate(`/${partner?.uuid}/settings/partner?tab=add`)}
        />
        <StatisticCard
          show={true}
          title={t("dashboard_screen.devices_activated")}
          totalCount={
            statisticsData.devices_count
              ? parseInt(statisticsData.devices_count) - parseInt(statisticsData.no_servicebooking_count)
              : ""
          }
          loading={loading}
          tooltip={t("dashboard_screen.tooltip.devices_activated")}
        />
        <StatisticCard
          show={true}
          title={t("dashboard_screen.devices_with_trips")}
          totalCount={statisticsData.trip_count}
          tooltip={t("dashboard_screen.tooltip.devices_with_trips")}
          loading={loading}
        />

        <StatisticCard
          show={true}
          title={t("dashboard_screen.reported_thefts")}
          tooltip={t("dashboard_screen.tooltip.reported_thefts")}
          totalCount={statisticsData.theft_reported_devices_count}
          loading={loading}
        />
      </Row>
    )
  }

  const renderTripsStatistics = () => {
    return (
      <>
        <label style={styles.cardTitle}> {t("dashboard_screen.trip_statistics")}</label>
        <Row>
          <Col>
            <StatisticCard
              show={true}
              title={t("dashboard_screen.average_trips")}
              tooltip={t("dashboard_screen.tooltip.devices_trip_avg")}
              totalCount={statisticsData.avg_trips}
              loading={loading}
            />
            <StatisticCard
              show={true}
              title={t("dashboard_screen.average_distance")}
              tooltip={t("dashboard_screen.tooltip.devices_distance_avg")}
              totalCount={statisticsData.avg_distance}
              loading={loading}
            />
          </Col>
          <Col>
            <StatisticCard
              show={true}
              title={t("dashboard_screen.total_trips")}
              tooltip={t("dashboard_screen.tooltip.devices_trip_total")}
              totalCount={statisticsData.total_trips}
              loading={loading}
            />
            <StatisticCard
              show={true}
              title={t("dashboard_screen.total_distance")}
              tooltip={t("dashboard_screen.tooltip.devices_distance_total")}
              totalCount={statisticsData.total_distance}
              loading={loading}
            />
          </Col>
          <Col>
            <StatisticCard
              show={true}
              title={t("dashboard_screen.average_co2")}
              tooltip={t("dashboard_screen.tooltip.co2_savings_avg")}
              totalCount={statisticsData.avg_co2}
              loading={loading}
            />
            <StatisticCard
              show={true}
              title={t("dashboard_screen.average_speed")}
              tooltip={t("dashboard_screen.tooltip.devices_speed")}
              totalCount={statisticsData.avg_speed}
              loading={loading}
            />
          </Col>
          <Col>
            <StatisticCard
              show={true}
              title={t("dashboard_screen.total_co2")}
              tooltip={t("dashboard_screen.tooltip.co2_savings_total")}
              totalCount={statisticsData.total_co2}
              loading={loading}
            />
          </Col>
        </Row>
      </>
    )
  }

  const renderStateOfTrackers = () => {
    return (
      <>
        <label style={styles.cardTitle}> {t("dashboard_screen.state_of_trackers")}</label>
        <Row>
          <DevicesCard
            title={t("dashboard_screen.devices_withouth_servicebooking")}
            devices={missingServiceBookingDevices}
            loading={deviceLoad}
            showTotal={true}
            totalValue={missingServiceBookingDevices.length}
            tooltip={t("dashboard_screen.tooltip.devices_withouth_servicebooking")}
            onDeviceClick={onDeviceClick}
            onDetailClick={() => {
              if (deviceLoad) return
              if (!permissions?.manageUserRoles) return
              navigate(`/${partner?.uuid}/settings/partner?tab=book_tracker`, {
                state: { devices: missingServiceBookingDevices, id: 1 },
              })
            }}
            formatDeviceTitle={(device) => `${device.serialnumber} - ${device.name}`}
          />
          <DevicesCard
            title={t("dashboard_screen.devices_with_ending_servicebookings", { value: "90" })}
            devices={expiringDevices}
            loading={deviceLoad}
            showDetail={true}
            showTotal={true}
            totalValue={expiringDevices.length}
            tooltip={t("dashboard_screen.tooltip.devices_with_ending_service")}
            onDetailClick={() => {
              if (deviceLoad) return
              if (!permissions?.manageUserRoles) return
              navigate(`/${partner?.uuid}/settings/partner?tab=book_tracker`, {
                state: { devices: expiringDevices, id: 2 },
              })
            }}
            onDeviceClick={onDeviceClick}
            formatDeviceTitle={(device) =>
              `${device.name}  ${
                device?.servicebooking ? "- " + formatDate(new Date(device?.servicebooking?.service_end_date)) : ""
              }`
            }
          />
        </Row>
        <Row>
          <Col>
            <DevicesCard
              title={t("dashboard_screen.devices_with_critical_battery")}
              devices={lowBatteryDevices}
              loading={deviceLoad}
              showTotal={true}
              totalValue={lowBatteryDevices.length}
              tooltip={t("dashboard_screen.tooltip.critical_battery")}
              onDeviceClick={onDeviceClick}
              formatDeviceTitle={(device) => `${device.name} - ${device.battery} %`}
            />
            <Row>
              <StatisticCard
                show={true}
                isColumnDetail={true}
                title={t("dashboard_screen.locked_devices")}
                tooltip={t("dashboard_screen.tooltip.devices_locked")}
                totalCount={statisticsData.locked_devices_count}
                loading={loading}
              />
              <StatisticCard
                show={true}
                isColumnDetail={true}
                title={t("dashboard_screen.unlocked_devices")}
                tooltip={t("dashboard_screen.tooltip.devices_unlocked")}
                totalCount={statisticsData.unlocked_devices_count}
                loading={loading}
              />
            </Row>
          </Col>
          <Col>
            <Row>
              <StatisticCard
                show={true}
                title={t("dashboard_screen.devices_with_movement")}
                tooltip={t("dashboard_screen.tooltip.devices_with_movement")}
                totalCount={statisticsData.movement_count}
                loading={loading}
              />
              <StatisticCard
                show={true}
                title={t("dashboard_screen.devices_with_movement_1h")}
                totalCount={statisticsData.movement_count}
                tooltip={t("dashboard_screen.tooltip.devices_with_movement_1h")}
                loading={loading}
              />
            </Row>
            <Row>
              <StatisticCard
                show={true}
                title={t("dashboard_screen.devices_with_valid_position_24h")}
                tooltip={t("dashboard_screen.tooltip.devices_with_valid_position_24h")}
                totalCount={statisticsData.valid_position_lastday_count}
                loading={loading}
              />
              <StatisticCard
                show={true}
                title={t("dashboard_screen.devices_with_valid_position")}
                tooltip={t("dashboard_screen.tooltip.devices_with_valid_position")}
                totalCount={statisticsData.valid_position_count}
                loading={loading}
              />
            </Row>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <Page
      showFleet
      showHeader
      isRelative
      headerValue={t("sidebar.statistics")}
      onSelectFleet={() => {
        setLoading(true)
        setDeviceLoad(true)
        // getStatistics()
        setDevices([])
      }}>
      {loading && renderLoaderSpinner()}
      {renderTopRow()}
      {renderTripsStatistics()}
      {renderStateOfTrackers()}
    </Page>
  )
}
