import React, { useEffect, useState } from "react"
import { Row, Col, Table, ListGroup, Spinner } from "react-bootstrap"
import { useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import * as Icon from "react-bootstrap-icons"
import { getPartnerUuidTripsId, getDeviceSerialnumberTrips, GET_TRIP_SHOW_RESPONSE, DATA_POINT_TYPE } from "@src/api"
import { TRIP } from "@src/api/types"
import { useLocalization } from "@src/localization"
import { usePartner } from "@src/partner"
import { checkIsCanTracker, gToKg, mToKm, sToH } from "@src/utils"
import { Map } from "../components"
import { PermissionCard } from "@src/components"
import { useDevice } from "@src/device/useDevice"
import { useUser } from "@src/user"
import { StyleSheet } from "@src/types"

type RouteTabProps = {
  serialnumber
}

const styles: StyleSheet = {
  map_sticky: {
    position: "sticky",
    top: 0,
  },
}

export const RouteTab: React.FC<RouteTabProps> = ({ serialnumber }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState<boolean>()
  const [selectedTrip, setSelectedTrip] = useState<GET_TRIP_SHOW_RESPONSE>()
  const { formatDate, formatTime } = useLocalization()
  const { partner } = usePartner()
  const { trips } = useDevice()
  const { t } = useTranslation()
  const tab = searchParams.get("tab")
  const tripId = parseInt(searchParams.get("trip"))
  const [gritValues, setGritValues] = useState([])
  const [workingWidthMax, setWorkingWidthMax] = useState(0)
  const [workingWidthMin, setWorkingWidthMin] = useState(0)
  const [isSCTCANTrip, setIsSCTCANTrip] = useState<boolean>(false)
  const { user, distanceConversion } = useUser()

  useEffect(() => {
    if (tripId && tripId != selectedTrip?.id) fetchTrip(tripId)
  }, [tripId])

  useEffect(() => {
    if (trips.length != 0) {
      if (!tripId && trips[0]?.id) updateSearchParams(trips[0]?.id)
    }
  }, [trips])

  const updateSearchParams = (tripId: number) => {
    searchParams.set("trip", tripId.toString())
    setSearchParams(searchParams, { replace: true })
  }

  const fetchTrip = (id: number) => {
    getPartnerUuidTripsId(partner?.uuid, id).then(({ response, data }) => {
      if (response.ok) setSelectedTrip(data)
      setLoading(false)
    })
  }

  const handleChoose = (e: React.SyntheticEvent, m: TRIP) => {
    setLoading(true)
    e.preventDefault()
    updateSearchParams(m.id)
  }

  const get_working_width_value = (datapoints: DATA_POINT_TYPE[]) => {
    const pumpActiveDatapoints = datapoints?.filter((datapoint) => datapoint.pump_active_flag)
    const gritValuesFromDataPoints = pumpActiveDatapoints?.map((datapoint) => datapoint.grit_value)
    setGritValues(gritValuesFromDataPoints)
    const workingWidthValues = pumpActiveDatapoints
      ?.map((datapoint) => datapoint.working_width_value)
      .filter((workingWidth) => workingWidth != null)
    setWorkingWidthMax(Math.max(...workingWidthValues))
    setWorkingWidthMin(Math.min(...workingWidthValues))
  }

  const renderGritNames = (values: number[]) => {
    const gritValuesUnique = []
    values.map((gritValue) => {
      if (gritValue === 0) {
        gritValuesUnique.push(t("grit"))
      } else if (gritValue === 1) {
        gritValuesUnique.push(t("salt"))
      } else if (gritValue === 2) {
        gritValuesUnique.push(t("custom"))
      }
    })
    const gritNames = [...new Set(gritValuesUnique)].filter((grit) => grit != null).join(", ")
    return gritNames || "-"
  }

  const renderTextRange = (min, max) => {
    if (!isFinite(min) || !isFinite(max)) return "-"
    return (
      (min === max ? min.toFixed(2) : `${min.toFixed(2)} - ${max.toFixed(2)}`) +
      ` ${selectedTrip?.datapoints[0].working_width_unit}`
    )
  }

  // if (!partner?.permissions?.can_see_device_routes) return <PermissionCard />

  useEffect(() => {
    if (selectedTrip && selectedTrip.datapoints) {
      if (checkIsCanTracker(selectedTrip?.datapoints)) {
        setIsSCTCANTrip(checkIsCanTracker(selectedTrip?.datapoints))
        get_working_width_value(selectedTrip?.datapoints)
      } else {
        setIsSCTCANTrip(false)
        setGritValues([])
        setWorkingWidthMax(0)
        setWorkingWidthMin(0)
      }
    }
  }, [selectedTrip])

  return (
    <div>
      <Row className="d-flex flex-column-reverse flex-lg-row bg-highlight">
        <Col>
          <Table hover={trips.length > 0} responsive className="table-borderless">
            <thead className="table-active">
              <tr>
                <th>{t("device_screen.route_tab.distance")}</th>
                <th>{t("device_screen.route_tab.date")}</th>
                <th>{t("device_screen.route_tab.start_time")}</th>
                <th>{t("device_screen.route_tab.end_time")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {trips &&
                trips?.map((trip) => (
                  <tr
                    className={`pointer ${tripId === trip.id ? "text-primary" : "bg-light"}`}
                    onClick={(e) => handleChoose(e, trip)}
                    key={trip.id}>
                    <td>{distanceConversion(trip.distance)}</td>
                    <td>{formatDate(new Date(trip.created_at))}</td>
                    <td>{formatTime(new Date(trip.trip_start))}</td>
                    <td>{formatTime(new Date(trip.trip_end))}</td>
                    <td>
                      {tripId === trip.id && loading ? (
                        <Spinner animation="border" size="sm" style={{ marginTop: 2 }} />
                      ) : (
                        <Icon.ChevronRight />
                      )}
                    </td>
                  </tr>
                ))}
              {trips.length == 0 && (
                <tr className="t">
                  <td colSpan={5}>{t("device_screen.route_tab.no_trip_available")}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
        <Col>
          <div className="mb-2" style={styles.map_sticky}>
            <Map
              position={tripId && selectedTrip?.datapoints.length != 0 && selectedTrip?.datapoints[0]}
              mapDevices={[]}
              routeDatapoints={tripId && selectedTrip?.datapoints}
              showRoute={true}
            />

            <ListGroup>
              {tripId && selectedTrip ? (
                <>
                  <ListGroup.Item>
                    {t("device_screen.route_tab.distance")}: {distanceConversion(selectedTrip.distance)}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {t("device_screen.route_tab.riding_time")}: {sToH(selectedTrip.duration_s)} h
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {t("device_screen.route_tab.avg_speed")}: {Math.round(selectedTrip.average_speed)}{" "}
                    {selectedTrip.average_speed_unit}
                  </ListGroup.Item>
                  {!isSCTCANTrip && (
                    <ListGroup.Item>
                      {t("device_screen.route_tab.co2saving")}: {Math.round(selectedTrip.pseudo_co2_saving)}{" "}
                      {selectedTrip.pseudo_co2_saving_unit}
                    </ListGroup.Item>
                  )}
                  {isSCTCANTrip && (
                    <>
                      <ListGroup.Item>
                        {t("device_screen.route_tab.working_width_value")}:{" "}
                        {renderTextRange(workingWidthMin, workingWidthMax)}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {t("device_screen.route_tab.grit_value")}: {renderGritNames(gritValues)}
                      </ListGroup.Item>
                    </>
                  )}
                </>
              ) : (
                <ListGroup.Item>{t("device_screen.route_tab.no_trip")}</ListGroup.Item>
              )}
            </ListGroup>
          </div>
        </Col>
      </Row>
    </div>
  )
}
